/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { FaArrowCircleUp } from "react-icons/fa"
import { Parallax } from "react-parallax"
import Header from "./header"
import "../../static/styles/main.scss"
import logo from "../../static/images/xeneco-logo.png"
import headerBackground from "../../static/images/xeneco_mediaintegrator_background.jpg"
import featureImage from "../../static/images/xeneco_mediaintegrator_computer.png"

const PageHeader = styled.div`
  /* background-image: url(${headerBackground});
  background-size: cover;
  background-position: center bottom; */
  text-shadow: 2px 2px 0px #0000004f;
  @media screen and (max-width: 960px) {
    text-shadow: 1px 1px 0px #0000004f;
  }
`

const SpecialLink = styled.p`
  display: flex;
  align-items: center;
  color: #5379b7;
  a {
    margin: 0 10px 0 0;
  }
  span {
    margin: 0px 5px 0 5px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    line-height: 1em;
  }
`

const getUrl = (path, lang) => {
  if (lang === "en") {
    return `/en/${path}`
  }
  return `/${path}`
}

const Layout = ({ children, lang }) => {
  const [showScroll, setShowScroll] = useState(false)
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    }

    document.addEventListener("scroll", checkScrollTop)
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", checkScrollTop)
    }
  }, [showScroll])
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Parallax
            // blur={10}
            bgImage={headerBackground}
            bgImageAlt="the cat"
            strength={200}
            blur={3}
          >
            <PageHeader>
              <Header siteTitle={data.site.siteMetadata.title} lang={lang} />
              <div className={"page-header home"}>
                {lang === "pl" ? (
                  <div>
                    <h1>Nowoczesne i efektywne rozwiązania dla rynku OOH</h1>
                    <p>
                      Naszym flagowym rozwiązaniem, dostarczanym od ponad dekady
                      dla rynku mediów reklamy zewnętrznej jest platforma
                      optymalizująco – planistyczna Outdoor Media Integrator.
                      Jest to pierwsze rozwiązanie na rynku reklamy OOH,
                      wspierające proces planowania kampanii outdoorowych.
                    </p>
                    <p> </p>
                  </div>
                ) : (
                  <div>
                    <h1>Most effective OOH solutions</h1>
                    <p>
                      Our main solutions is an optimization and planning
                      platform Outdoor Media Integrator which supports the
                      process out of home campaign planning.
                    </p>
                    <p> </p>
                  </div>
                )}
                <img alt={"Dashboard"} src={featureImage} />
              </div>
            </PageHeader>
          </Parallax>
          <FaArrowCircleUp
            className="scrollTop"
            onClick={scrollTop}
            style={{
              height: 40,
              width: 40,
              display: showScroll ? "block" : "none",
            }}
          />
          <main>{children}</main>
          <footer id="footer">
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col-2"}></div>
                <div className={"col-4"}>
                  <div className={"widget__item"}>
                    <div className={"logo"}>
                      <Link to={getUrl("", lang)} title={"Xeneco"}>
                        <img alt={"Logo"} src={logo} />
                      </Link>
                    </div>

                    <div className={"about"}>
                      <p>Xeneco sp. z o.o.</p>
                      <p>ul. Ateńska 10 lok. 14</p>
                      <p>03-978 Warszawa</p>
                      <p>&nbsp;</p>
                      <p>Tel.: +48 22 205 00 35</p>
                      <SpecialLink>
                        <a
                          className={"links__special"}
                          href="mailto:xeneco@xeneco.pl"
                        >
                          xeneco@xeneco.pl
                        </a>
                      </SpecialLink>
                    </div>
                  </div>
                </div>
                <div className={"col-4"}>
                  <div className={"widget__item"}>
                    <div className={"about"}>
                      <p>KRS: Numer 0000315544</p>
                      <p>Sąd Rejonowy dla m.st. Warszawy XIII Wydział KRS</p>
                      <p>NIP: 1132742070</p>
                      <p>REGON: 141608821</p>
                      <p>Kapitał zakładowy 5 000 zł w całości opłacony</p>
                      <SpecialLink>
                        <a href="/" className={"links__specialempty"}>
                          &nbsp;
                        </a>
                      </SpecialLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className={"copyright"}>
                {lang === "pl" ? (
                  <p>
                    Copyright {new Date().getFullYear()}, Xeneco. Wszystkie
                    prawa zastrzeżone.
                  </p>
                ) : (
                  <p>
                    Copyright {new Date().getFullYear()}, Xeneco. All rights
                    reserved.{" "}
                  </p>
                )}

                {lang === "pl" ? (
                  <div className="links">
                    {/* <Link to="/privacy" title={"Polityka prywatności"}>
                      Polityka prywatności
                    </Link>
                    |
                    <Link to="/terms" title={"Warunki korzystania"}>
                      Warunki korzystania
                    </Link>
                    | */}
                    <Link to="/en/" title={"Change language"}>
                      Switch to english
                    </Link>
                  </div>
                ) : (
                  <div className="links">
                    {/* <Link to="/privacy" title={"Privacy Policy"}>
                      Privacy
                    </Link>
                    |
                    <Link to="/terms" title={"Terms Of Use"}>
                      Terms Of Use
                    </Link>
                    | */}
                    <Link to="/" title={"Zmień język"}>
                      Przełącz na polski
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </footer>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
}

export default Layout
