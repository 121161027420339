import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../static/images/xeneco-logo-2.png"
import { Link as Scroll } from "react-scroll"

// const getLanguage = lang => {
//   return lang === "pl" ? (
//     <>
//       <Link to="/" onMouseOver={() => {}} className="lang">
//         PL
//       </Link>
//       <Link to="/en/" className="lang-hidden">
//         EN
//       </Link>
//     </>
//   ) : (
//     <>
//       <Link to="/en/" className="lang">
//         EN
//       </Link>
//       <Link to="/" className="lang-hidden">
//         PL
//       </Link>
//     </>
//   )
// }

const Header = ({ siteTitle, lang }) => (
  <header>
    <div className={"container"}>
      <div className={"top-menu"}>
        <div className={"logo"}>
          <Link to={lang === "pl" ? "/" : "/en/"} title={"Xeneco"}>
            <img alt={"Logo"} src={logo} />
          </Link>
        </div>
        <div className={"menu"}>
          <Scroll to="omi" smooth={true}>
            OMI
          </Scroll>
          <Scroll to="clients" smooth={true}>
            {lang === "pl" ? "ZAUFALI NAM" : "TRUSTED US"}
          </Scroll>
          <Scroll to="about" smooth={true}>
            {lang === "pl" ? "O NAS" : "ABOUT"}
          </Scroll>
          <Scroll to="footer" smooth={true}>
            {lang === "pl" ? "KONTAKT" : "CONTACT"}
          </Scroll>
          {/* {getLanguage(lang)} */}
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  lang: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  lang: `pl`,
}

export default Header
